import "../styles/Work.css";

export const Contact = () =>{
  return (  
    <div className="container spacer" id="contact">
      <div className="col-md-9 mx-auto text-center mb-5">
          <h4 className="text-secondary" data-aos="fade-up" data-aos-duration="1000"><span>Get In Touch</span></h4>          
            <p className="text-secondary" data-aos="fade-up" data-aos-duration="1500">Have an exciting project where you need some help?</p>
            <a href="https://www.instagram.com/erwin.agpasa" className="btn btn-outline-secondary btn-lg" data-aos="fade-up" data-aos-duration="1800" target="_blank" rel="noreferrer">Send me over a message</a>
      </div>
    </div>
  )
}

