import "../styles/About.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faCoffee, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faReact,faNodeJs, faSwift, faAws, faJsSquare, faSquareTumblr, faGoogle } from '@fortawesome/free-brands-svg-icons'

export const About = () =>{
  return (  
    <div className="container spacer" id="about">
      <div className="col-md-9 mx-auto">   

        <div className="row">
        <h4 className="text-secondary decorated" data-aos="fade-up" data-aos-duration="1000"><span>About Me</span></h4> 

          <div className="col-md-8">
          <p className="mt-3 text-secondary" data-aos="fade-up" data-aos-duration="1000">
            I'm an experienced software developer who constantly seeks out innovative solutions 
            to everyday problems. In my 8+ years in this industry, I've honed my analytical 
            thinking and collaboration skills, and I love working with a team. 
            I've also had the opportunity to serve as the full-stack developer for 
            Internal projects with Ibn Khuldoon National School.            
            </p>

            <p className="text-secondary" data-aos="fade-up" data-aos-duration="1200">
            Prior to this position, I worked as a web designer for Sendhive.io and 
            Platinum Group Media Inc. I involved to web and mobile app development 
            while working there, which has been my specialty.</p>

            <p className="text-secondary" data-aos="fade-up" data-aos-duration="1200">
            Here are a few technologies I've been working with recently:
            </p>

            <div className="row">
              <div className="col">
              <ul className="list-group" data-aos="fade-up" data-aos-duration="1200">
                <li className="list-group-item"><FontAwesomeIcon icon={faJsSquare} /> React/NextJS</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faNodeJs} /> Node.js</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faSquareTumblr} /> TypeScript</li>
              </ul>
              </div>
              <div className="col">
              <ul className="list-group list-group" data-aos="fade-up" data-aos-duration="1200">
                <li className="list-group-item"><FontAwesomeIcon icon={faMobileAlt} /> Flutter (Dart)</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faSwift} /> Swift</li>
                <li className="list-group-item"><FontAwesomeIcon icon={faReact} /> Apollo/GraphQL</li>
              </ul>
              </div>

              <div className="col">
              <ul className="list-group list-group" data-aos="fade-up" data-aos-duration="1200">
              <li className="list-group-item"><FontAwesomeIcon icon={faAws} /> AWS</li>
              <li className="list-group-item"><FontAwesomeIcon icon={faGoogle} /> Firebase</li>
              <li className="list-group-item"><FontAwesomeIcon icon={faDatabase} /> SQL/NoSQL</li>
              
              </ul>
              </div>
            </div>    
          </div>

          <div className="col-md-4 text-center">
          <img src={require('../assets/img/erwin_agpasa.png')} alt="me" className="me rounded img-fluid"/>
            <small className="mt-3 text-secondary d-block">
              <a href="https://www.paypal.com/donate/?hosted_button_id=83X4VR23JDGK2" className="btn btn-outline-secondary" target="_blank" rel="noreferrer">          
              <FontAwesomeIcon icon={faCoffee} /> Buy me a coffee
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}