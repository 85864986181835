import "../styles/Brands.css";
import Marquee from "react-fast-marquee";

export const Brands = () => {
  return (
    <div className="container spacer" id="contact">
      <div className="col-md-9 mx-auto text-center mb-5">
        <p className="text-secondary">Sponsored by (not actually):</p>
        <Marquee>
          {/* <img className="brands" alt="" src={require('../assets/logo/hatchup.png')} /> */}
          <img className="brands" alt="" src={require('../assets/logo/audiocheck.png')} />
          <img className="brands" alt="" src={require('../assets/logo/ibm.png')} />
          <img className="brands" alt="" src={require('../assets/logo/quest.png')} />
          <img className="brands" alt="" src={require('../assets/logo/nasa.png')} />
          <img className="brands" alt="" src={require('../assets/logo/redhat.png')} />
          <img className="brands" alt="" src={require('../assets/logo/fedora.png')} />
        </Marquee>
      </div>
    </div>
  )
}

