import {Routes, Route} from 'react-router-dom'
import {Homepage} from './views/Homepage'
import {PowerSchool} from './views/PowerSchool'

function App() {
  return (
    <Routes>
      <Route path='/' element={<Homepage />} />
      <Route path='/powerschool' element={<PowerSchool />} />
      </Routes>
  );
}

export default App;
