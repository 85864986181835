import "../styles/Work.css";
import { Link } from "react-router-dom";

export const Work = () =>{
  return (  
    <div className="container spacer" id="experience">
      <div className="col-md-9 mx-auto">      
          <h4 className="text-secondary decorated mb-5" data-aos="fade-up" data-aos-duration="1000"><span>Where I've Worked</span></h4>          
          
          <div className="work-wrap d-flex align-items-start" data-aos="fade-up" data-aos-duration="1000">
            <div className="col-md-4 work-nav nav-pills me-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <Link className="work-nav-link nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Ibn Khuldoon National School</Link>
              <Link className="work-nav-link nav-link" id="v-pills-sendhive-tab" data-bs-toggle="pill" data-bs-target="#v-pills-sendhive" type="button" role="tab" aria-controls="v-pills-sendhive" aria-selected="false">Sendhive.io</Link>
              <Link className="work-nav-link nav-link" id="v-pills-platinum-tab" data-bs-toggle="pill" data-bs-target="#v-pills-platinum" type="button" role="tab" aria-controls="v-pills-platinum" aria-selected="false">Platinum Group Media Inc.</Link>
              <Link className="work-nav-link nav-link" id="v-pills-audiocheck-tab" data-bs-toggle="pill" data-bs-target="#v-pills-audiocheck" type="button" role="tab" aria-controls="v-pills-audiocheck" aria-selected="false">Audiocheck.ca</Link>
              <Link className="work-nav-link nav-link" id="v-abs-cbn-tab" data-bs-toggle="pill" data-bs-target="#abs-cbn" role="tab" aria-controls="abs-cbn" aria-selected="false">ABS-CBN Broadcasting Corporation</Link>
            </div>
            
            <div className="tab-content" id="v-pills-tabContent">
              <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                <h6>Full Stack Web and Mobile Developer <span className="text-blue">@ Ibn Khuldoon National School</span></h6> 
                <small className="d-block text-secondary">Educational Area Isa Town, Bahrain | May 2019 - Present</small>
                <ul className="mt-3">
                  <li>Building a CI/CD Pipeline with AWS CodePipeline to Deploy ReactJS, and NodeJS for internal project of the school.</li>
                  <li className="mt-3">Improved legacy front-end by replacing it with ReactJS, and ExpressJS. Resulted into 50% faster loading times for our data heavy CRM application.</li>
                  <li className="mt-3">Designed, Implemented, and Tested Flutter and Dart Code using BloC Patterns for iOS and Android.</li>
                  <li className="mt-3">Work with the Student Information System Administrator to ideate software solutions such as Powerschool SIS Customization.</li>
                </ul>
              </div>
              
              <div className="tab-pane fade" id="v-pills-sendhive" role="tabpanel" aria-labelledby="v-pills-sendhive-tab" tabIndex="0">
              <h6>UI-UX Designer/Web Developer (Remote) <span className="text-blue">@ Sendhive.io</span></h6> 
              <small className="d-block text-secondary"> 5 Cypress Point Dr. Mountain View, CA | 2011 March - 2019 January</small>
                <ul className="mt-3">
                  <li>Designing, Building, and Maintaining Product Landing Pages using React, Bootstrap Framework, and NodeJS for the Back-end.</li>
                  <li className="mt-3">Produce designs to help the entire product team understand the desired customer experience: storyboards, flows, wire-frames and prototypes.</li>
                </ul>
              </div>

              <div className="tab-pane fade" id="v-pills-platinum" role="tabpanel" aria-labelledby="v-pills-platinum-tab" tabIndex="0">
                <h6>Web Designer/Developer (Remote) <span className="text-blue">@ Platinum Group Media Inc.</span></h6> 
                <small className="d-block text-secondary">9450 SW Gemini Dr #94397 Beaverton, OR | 2009 March - 2019 January</small>              
                <ul className="mt-3">
                  <li>Maintained and updated website functionalities in collaboration with the backend and mobile development teams.</li>
                  <li className="mt-3">Created complex graphics and professional designs for a variety of businesses, organisations, professionals and individuals, including custom graphic and mobile mockup.</li>
                  <li className="mt-3">Developed user-interface architecture and navigation for websites improving usability and resulted in increased traffic.</li>
                </ul>
              </div>

              <div className="tab-pane fade" id="v-pills-audiocheck" role="tabpanel" aria-labelledby="v-pills-audiocheck-tab" tabIndex="0">
              <h6>Web Designer (Remote) <span className="text-blue">@ audiocheck.ca</span></h6> 
                <small className="d-block text-secondary">4th Ave NE Calgary, Alberta T2A3Y1 Canada | 2008 December - 2019 October</small>
                <ul className="mt-3">
                  <li>Developed efficient and maintainable applications based on business objectives and client needs</li>
                  <li className="mt-3">Created graphics and website mockup.</li>
                </ul>
              </div>

              <div className="tab-pane fade" id="abs-cbn" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
              <h6>Computer Graphics Artist <span className="text-blue">@ ABS-CBN Broadcasting Corporation</span></h6> 
              <small className="d-block text-secondary">Laoag City Philippines | 2002 July - 2004 January</small>
                <ul className="mt-3">
                  <li>Graphics designing, digital image editing and animation using Adobe Premiere, and After Effects.</li>
                  <li className="mt-3">Video editing using nonlinear and linear editing systems.</li>
                  <li className="mt-3">Computer hardware troubleshooting and network maintenance.</li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}