import "../styles/Work.css";

export const Footer = () =>{
  return (  
    <div className="container spacer">
      <div className="col-md-12 mx-auto text-center mb-3">      
      <small className="text-secondary">Copyright &copy; {new Date().getFullYear()} Erwin Agpasa</small>
      <small className="d-block text-secondary">Version 1.0.2 | Build 1</small>      
      </div>
    </div>
  )
}