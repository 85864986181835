import React from 'react';
import { Link } from "react-scroll";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Link } from "react-router-dom";

export const Navigation = () =>{

  let prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
      let currentScrollPos = window.pageYOffset;
    
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("main_nav").style.top = "0";
          } else {
          document.getElementById("main_nav").style.top = "-100px";
          }
          prevScrollpos = currentScrollPos;
    }

    const notify = () => toast("You should chat me!");


  return (
    <div>
    <nav id="main_nav" className="navbar navbar-expand-lg navbar-dark2">
      <div className="container-fluid">        
        <Link to="/" className="navbar-brand">
        <img src={require('../assets/logo/logo.png')} alt="logo" className="main-logo"/>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"  aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            {/* <li className="nav-item"><Link className="nav-link" aria-current="page" to="/powerschool">Powerschool</Link></li>         */}
            <li className="nav-item"><Link className="nav-link" aria-current="page" smooth spy to="about">About</Link></li>        
            <li className="nav-item"><Link className="nav-link" aria-current="page" smooth spy to="experience">Experience</Link></li>        
            <li className="nav-item"><Link className="nav-link" aria-current="page" smooth spy to="project">Work</Link></li> 
            <li className="nav-item"><Link className="nav-link" aria-current="page" smooth spy to="contact">Contact</Link></li>               
            <li className="nav-item d-none d-sm-block"><Link to="#" onClick={notify} className="btn btn-outline-secondary">Resume <ToastContainer theme="dark"/></Link></li>
          </ul>
        </div>
      </div>
    </nav>
     
 </div>
  )
}