import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faGithub } from '@fortawesome/free-brands-svg-icons';
import { HiArrowLongRight } from "react-icons/hi2";
import "../styles/Hero.css";

export const Hero = () =>{
  return (  
    <div className="container spacer">
      <div className="col-md-9 mx-auto heading">   
        <p className="text-secondary" data-aos="fade" data-aos-duration="1000">Hi, my name is</p>  
        <h2 className="heading" data-aos="fade-up" data-aos-duration="1000">Erwin Agpasa
        <span className="d-block text-secondary" data-aos="ease-in" data-aos-duration="2000">I'm a software developer</span></h2>

        <div className="col-md-8">
        <p className="text-secondary" data-aos="fade" data-aos-duration="2800">
        I create innovative solutions that deliver exceptional user experiences. 
        With a strong foundation in programming languages and software development frameworks, 
        I design, develop, and test software applications across various platforms. 
        I stay up-to-date with the latest trends and technologies to ensure that my work is 
        cutting-edge and of high quality.
        </p>
        </div>

        <a href="https://github.com/erwinagpasa" target="_blank" rel="noreferrer" className="btn btn-secondary btn-lg">
        Check out my Github <FontAwesomeIcon icon={faGithub} /></a>

        <a href="https://blog.devph.io" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg">
        Powerschool Customization <HiArrowLongRight /></a>

      </div>      
    </div>
  )
}